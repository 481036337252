// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `canvas {
  height: auto;
  display: block;
  border: 1px solid #bbb2b2;
  background-color: #f1eeee;
  margin: 30px auto;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/modales/modal-firma/modal-firma.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,cAAA;EACA,yBAAA;EACA,yBAAA;EACA,iBAAA;AACJ","sourcesContent":["canvas {\n    height: auto;\n    display: block;\n    border: 1px solid #bbb2b2;\n    background-color: #f1eeee;\n    margin: 30px auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
