import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Storage } from '@ionic/storage';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentesCompartidosModule } from './componentes/componentes-compartidos.module';
import { CommonDirectivesModule } from './directivas/common-directives.module';
import { I18nModule } from './i18n.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

const config: SocketIoConfig = { url: environment.api_url_socket, options: { transports: ['websocket'], reconnection: true } };

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        CommonDirectivesModule,
        ComponentesCompartidosModule,
        I18nModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        SocketIoModule.forRoot(config)
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [CallNumber, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, Storage,],
    bootstrap: [AppComponent]
})
export class AppModule {}
