import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./paginas/autenticacion/aut-login/aut-login.module').then( m => m.AutLoginPageModule)
  },
  {
    path: 'aut-reestablecer-pass',
    loadChildren: () => import('./paginas/autenticacion/aut-reestablecer-pass/aut-reestablecer-pass.module').then( m => m.AutReestablecerPassPageModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./paginas/tabs/tabs.module').then(m => m.TabsPageModule)
      },
      {
        path: 'clientes-panel',
        loadChildren: () => import('./paginas/clientes/clientes-panel/clientes-panel.module').then( m => m.ClientesPanelPageModule)
      },
      {
        path: 'usuario-panel',
        loadChildren: () => import('./paginas/usuarios/usuario-panel/usuario-panel.module').then( m => m.UsuarioPanelPageModule)
      },
      {
        path: 'usuario-cobros-incentivos',
        loadChildren: () => import('./paginas/usuarios/usuario-cobros-incentivos/usuario-cobros-incentivos.module').then( m => m.UsuarioCobrosIncentivosPageModule)
      },
      {
        path: 'com-chats',
        loadChildren: () => import('./paginas/comunicacion/com-chats/com-chats.module').then( m => m.ComChatsPageModule)
      },
      {
        path: 'com-conversacion',
        loadChildren: () => import('./paginas/comunicacion/com-conversacion/com-conversacion.module').then( m => m.ComConversacionPageModule)
      },
      {
        path: 'com-envio',
        loadChildren: () => import('./paginas/comunicacion/com-envio/com-envio.module').then( m => m.ComEnvioPageModule)
      },
      {
        path: 'pedido-detalle',
        loadChildren: () => import('./paginas/pedidos/pedido-detalle/pedido-detalle.module').then( m => m.PedidoDetallePageModule)
      },
      {
        path: 'empresas-listado',
        loadChildren: () => import('./paginas/empresas/empresas-listado/empresas-listado.module').then( m => m.EmpresasListadoPageModule)
      },
      {
        path: 'empresa-detalle',
        loadChildren: () => import('./paginas/empresas/empresa-detalle/empresa-detalle.module').then( m => m.EmpresaDetallePageModule)
      },
      {
        path: 'clientes-form-visita',
        loadChildren: () => import('./paginas/clientes/clientes-form-visita/clientes-form-visita.module').then( m => m.ClientesFormVisitaPageModule)
      },
      {
        path: 'clientes-form-visita-aerotermia',
        loadChildren: () => import('./paginas/clientes/clientes-form-visita-aerotermia/clientes-form-visita-aerotermia.module').then( m => m.ClientesFormVisitaAerotermiaPageModule)
      },
      {
        path: 'clientes-form-instalacion',
        loadChildren: () => import('./paginas/clientes/clientes-form-instalacion/clientes-form-instalacion.module').then( m => m.ClientesFormInstalacionPageModule)
      },
      {
        path: 'clientes-listado-todos',
        loadChildren: () => import('./paginas/clientes/clientes-listado-todos/clientes-listado-todos.module').then( m => m.ClientesListadoTodosPageModule)
      },
      {
        path: 'clientes-form-incidencia',
        loadChildren: () => import('./paginas/clientes/clientes-form-incidencia/clientes-form-incidencia.module').then( m => m.ClientesFormIncidenciaPageModule)
      },
      {
        path: 'ayuda-preguntas-frecuentes',
        loadChildren: () => import('./paginas/ayuda/ayuda-preguntas-frecuentes/ayuda-preguntas-frecuentes.module').then( m => m.AyudaPreguntasFrecuentesPageModule)
      },
      {
        path: 'config-spin',
        loadChildren: () => import('./paginas/spin/config-spin/config-spin.module').then( m => m.ConfigSpinPageModule)
      },
      {
        path: 'spin-inicio',
        loadChildren: () => import('./paginas/spin/spin-inicio/spin-inicio.module').then( m => m.SpinInicioPageModule)
      },
      {
        path: 'detalle-dispositivo-spin',
        loadChildren: () => import('./paginas/spin/detalle-dispositivo-spin/detalle-dispositivo-spin.module').then( m => m.DetalleDispositivoSpinPageModule)
      },
      {
        path: 'solicitud-material-ferreteria',
        loadChildren: () => import('./paginas/material-ferreteria/solicitud/solicitud.module').then( m => m.SolicitudPageModule)
      },
      {
        path: 'carrito-material-ferreteria',
        loadChildren: () => import('./paginas/material-ferreteria/carrito/carrito.module').then( m => m.CarritoPageModule)
      },
      {
        path: 'solicitud-material-ferreteria-finalizada',
        loadChildren: () => import('./paginas/material-ferreteria/solicitud-finalizada/solicitud-finalizada.module').then( m => m.SolicitudFinalizadaPageModule)
      },
      {
        path: 'solicitud-material-ferreteria-realizada',
        loadChildren: () => import('./paginas/material-ferreteria/solicitud-realizada/solicitud-realizada.module').then( m => m.SolicitudRealizadaPageModule)
      },
      {
        path: 'solicitudes-material-ferreteria-realizadas',
        loadChildren: () => import('./paginas/material-ferreteria/solicitudes-realizadas/solicitudes-realizadas.module').then( m => m.SolicitudesRealizadasPageModule)
      },
      {
        path: 'clientes-form-instalacion-aerotermia',
        loadChildren: () => import('./paginas/clientes/clientes-form-instalacion-aerotermia/clientes-form-instalacion-aerotermia.module').then( m => m.ClientesFormInstalacionAerotermiaPageModule)
      },
      {
        path: 'cambiar-contrasena',
        loadChildren: () => import('./paginas/usuarios/cambiar-contrasena/cambiar-contrasena.module').then( m => m.CambiarContrasenaPageModule)
      }, 
      {
        path: 'configuracion-usuario',
        loadChildren: () => import('./paginas/usuarios/configuracion-usuario/configuracion-usuario.module').then( m => m.ConfiguracionUsuarioPageModule)
      },
    ]
  },
  


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
