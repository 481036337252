import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-modal-ver-imagen',
  templateUrl: './modal-ver-imagen.component.html',
  styleUrls: ['./modal-ver-imagen.component.scss'],
})
export class ModalVerImagenComponent implements OnInit {
  @Input() image: string;

  public config: SwiperOptions = {
    zoom: true
  }

  public slideOpts = {
    zoom: {
      maxRatio: 3,
    },
  }
  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
  }

  onCloseModal() {
    this.modalController.dismiss();
  }

}
