import { Directive, Input, HostListener, Renderer2, ElementRef } from '@angular/core';
import { ImgTipoError } from 'src/app/enums/img-tipo-error.enum';

@Directive({
  selector: 'ion-img[ImgError]',
  host: {
    '(ionError)': 'onError()',
    '(ionImgWillLoad)': 'ionImgWillLoad()',
    '(ionImgDidLoad)': 'onLoad()',
    //'[src]':'src'
   }
})
export class ImgErrorDirective {
  @Input('ImgError') imgError: string;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) { 
  }

  ionImgWillLoad() {
    this.renderer.addClass(this.el.nativeElement, 'img-loader');
  }
  
  onLoad() {
    this.renderer.removeClass(this.el.nativeElement, 'img-loader');
  }
  
  onError() {
    this.renderer.removeClass(this.el.nativeElement, 'img-loader');
    switch (this.imgError) {
      case ImgTipoError.usuarios:
        this.renderer.setAttribute(this.el.nativeElement, 'src', 'assets/default/150x150-usuarios.png');  
        break;

      default:
        this.renderer.setStyle(this.el.nativeElement, "display", "none");
        break;
    }
  }

}
