<ion-content>
  <div>
    <p class="ion-padding ion-text-center">{{"modal-firma.txt-descripcion" | translate}}</p>
    <canvas (click)="onCanvas()" [width]="platform.width()-2" #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)"></canvas>
  </div>
  <!-- <img src='{{ signatureImg }}' /> -->
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col size="4" class="ion-text-center">
          <ion-button color="danger" (click)="onCancel()">{{"modal-firma.btn-cancelar" | translate}}</ion-button>
        </ion-col>
        <ion-col size="4" class="ion-text-center">
          <ion-button [disabled]="!status" color="warning" (click)="onClearPad()">{{"modal-firma.btn-limpiar" | translate}}</ion-button>
        </ion-col>
        <ion-col size="4" class="ion-text-center">
          <ion-button [disabled]="!status" color="success" (click)="onSavePad()">{{"modal-firma.btn-aceptar" | translate}}</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>