import { AutenticacionService } from 'src/app/servicios/autenticacion/autenticacion.service';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, Observer } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, tap, timeout } from 'rxjs/operators';
import { AlertasService } from '../alertas/alertas.service';
import { UsuarioService } from '../singleton/usuario.service';
import { lastValueFrom } from 'rxjs';

let httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
};

const timeoutService: number = 20000;
const ttlCache: number = 1200; // 20 min

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  app_tokenTemp: string;
  api_url = environment.api_url;

  constructor(
    private http: HttpClient,
    private alertasService: AlertasService,
    private usuService: UsuarioService,
    private autenticacionService: AutenticacionService,
  ) {
  }

  private handleError<T> (operation = 'operation', hideMsg: boolean = false, result?: T) {
    return (dataErr: HttpErrorResponse): Observable<T> => {
      let errorsExceptionsMsg = [
        //450, // Token vencido
        //451, // Token inválido
      ];

      // Error 401 Error redirigir al login

      // TODO: send the error to remote logging infrastructure
      console.error(dataErr); // log to console instead
      let msgError = "Ocurrió un error";
      if (Number(dataErr?.error?.status) == 401) {
        this.alertasService.presentToast(dataErr?.error.description);
        this.alertasService.closePresentLoading();
        this.autenticacionService.logout();
        return;
      }
      if (dataErr?.status === 0) {
        msgError = "There is no Internet connection";
      } else if (!hideMsg && errorsExceptionsMsg.indexOf(dataErr?.status) == -1) {
        if (dataErr && dataErr?.error && dataErr?.error?.error && typeof dataErr?.error?.error !== 'object') {
          msgError = dataErr?.error?.error;
        } else if (typeof dataErr?.error?.error === 'object') {
          for (let keyErr in dataErr?.error.error){
            if (Array.isArray(dataErr?.error.error[keyErr])) {
              msgError = dataErr?.error.error[keyErr].join(',');
            }
          }
        } else if (dataErr && dataErr?.error?.description) {
          msgError = dataErr?.error.description;
        } else if (typeof dataErr?.error === 'object') {
          for (let keyErr in dataErr?.error){
            if (Array.isArray(dataErr?.error[keyErr])) {
              msgError = dataErr?.error[keyErr].join(',');
            }
          }
        } else if (dataErr && dataErr?.error?.description) {
          msgError = dataErr?.error?.description;
        } else if (dataErr && dataErr?.error && typeof dataErr?.error === 'object') {
          msgError = dataErr?.error?.description;
        }
        this.alertasService.closePresentLoading();
        this.alertasService.presentToast(msgError);
      }
      // Let the app keep running by returning an empty result.
      return throwError(() => dataErr);
    };
  }

  get(method, params?, hideMsg: boolean = false, timeOutIn: number = timeoutService, withCache: boolean = false): Observable<any> {
    if (!params) params = {};
    params.appname = environment.app_name_slug;
    params.version_app_clientes = environment.version_android;
    if(this.usuService?.usu?.app_token){
      httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer '+this.usuService.usu.app_token)
    }
    params = Object.keys(params).map(function(k) {
      return encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
    }).join('&');
    if (params != "") {
      if (method.indexOf("?") >= 0) {
        params = "&"+params;
      }else{
        params = "?"+params;
      }
    }
  return this.http.get<any>(this.api_url + method + params, httpOptions)
    .pipe(timeout(timeOutIn))
    .pipe(
      catchError(this.handleError<any>('get', hideMsg))
    );
  }

  post(method, params, isFormData: boolean = false, hideMsg: boolean = false, timeOutIn: number = timeoutService): Observable<any> {
    if (!isFormData) {
      if (!params) params = {};
      params.appname = environment.app_name_slug;
      params.version_app_clientes = environment.version_android;
    } else {
      params.append('appname', environment.app_name_slug);
      params.append('version_app_clientes', environment.version_android);
    }
    if (isFormData) {
      httpOptions.headers = httpOptions.headers.delete("Content-Type");
    }
    if(this.usuService?.usu?.app_token){
      httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer '+this.usuService.usu.app_token);
    }
    return this.http.post<any>(this.api_url + method, params, httpOptions)
      .pipe(timeout(timeOutIn))
      .pipe(
        catchError(this.handleError<any>('post', hideMsg))
      );
  }

  put(method, params, hideMsg: boolean = false, timeOutIn: number = timeoutService): Observable<any> {
    if (!params) params = {};
    params.appname = environment.app_name_slug;
    params.version_app_clientes = environment.version_android;
    if(this.usuService?.usu?.app_token){
      httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer '+this.usuService.usu.app_token);
    }
    return this.http.put(this.api_url + method, params, httpOptions)
      .pipe(timeout(timeOutIn))
      .pipe(
        catchError(this.handleError<any>('update', hideMsg))
      );
  }

  delete(method, params?, hideMsg: boolean = false, timeOutIn: number = timeoutService): Observable<any> {
    if (!params) params = {};
    params.appname = environment.app_name_slug;
    params.version_app_clientes = environment.version_android;
      if(this.usuService?.usu?.app_token){
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer '+this.usuService.usu.app_token);
      }
      params = Object.keys(params).map(function(k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
      }).join('&');
      if (params != "") params = "?"+params;
    return this.http.delete<any>(this.api_url + method + params, httpOptions)
      .pipe(timeout(timeOutIn))
      .pipe(
        catchError(this.handleError<any>('delete', hideMsg))
      );
  }

}
