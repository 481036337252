import { format, formatISO, parseISO } from 'date-fns';
import { EstadosAgenda } from '../enums/estados-agenda.enum';
import { EstadosClientes } from '../enums/estados-clientes.enum';
import { SegClientesListado } from '../enums/seg-clientes-listado.enum';
import { ICliente } from '../interfaces/ICliente';
import { Fichero } from './fichero.model';
import { Incidencia } from './incidencia.model';

export class Cliente implements ICliente {
  id: number;
  dni: string;
  nombre: string;
  apellidos: string;
  ref: string;
  email: string;
  fecha_visita: string;
  fecha_instalacion_inicio: string;
  fecha_fase_planificacion: string;
  fecha_informefininstalacion: string;
  fecha_emisioninformevisita: string;
  fecha_instalacion_planificada: string;
  fecha_concesionlicencia: string;
  fecha_desplazamientoincidencia: string;
  direccion: string;
  municipio: string;
  provincia: string;
  region: string;
  cp: string;
  modalidad_autoconsumo: string;
  potencia_instalacion_estimada: number;
  firmada_hoja_encargo: boolean;
  interno: boolean;
  gerente: boolean;
  lat: string;
  lon: string;
  informe_visita: boolean;
  informe_instalacion: boolean;
  valor: number;
  estadoslug: EstadosClientes;
  estadolabel: string;
  equipos: Array<{
    clave_tipo: string;
    cantidad: string;
    consumo: string;
    modelo: string;
    equipo_id: string;
    nombre_tipo: string;
    marca: string;
    num_serie: string;
    valornominal: number;
    garantia:string;
  }>;
  disposiciones_imagenes: Array<string>;
  disposicion: boolean;
  tipo_instalacion: string;
  tipo_cliente: string;
  tipo_propiedad: string;
  grua: string;
  seleccionador: boolean;
  comentario_instalador: string;
  comentario_fecha_instalacion_inicio: string;
  distribuidoras: Array<{
    id: number,
    nombre: string,
  }> = [];
  puede_agendar_instalacion: boolean;
  desplazamientoincidencia: boolean;
  historial_cliente: Array<{
    nombre: string;
    fecha: string;
    estado: string;
  }>;
  documentos_cliente: Array<Fichero>;
  telefono: string;
  puede_form_visita: boolean;
  puede_form_baterias: boolean;
  puede_form_cargadores: boolean;
  gestiona_boletin: boolean;
  doc_legalizacion_completa: boolean;
  tiene_imagenes_legalizacion: boolean;
  hace_incidencia: boolean;
  hace_instalacion: boolean;
  hace_montaje: boolean;
  hace_visita: boolean;
  hace_legalizacion: boolean;
  informes_fin_instalacion: Array<Fichero>;
  informes_visita: Array<Fichero>;
  informes_incidencia: Array<Fichero>;
  imagenes_fin_instalacion: Array<Fichero>;
  imagenes_visita: Array<Fichero>;
  imagenes_incidencia: Array<Fichero>;
  imagenes_legalizacion: Array<Fichero>;
  clave_ente: string;
  incidencias: Array<Incidencia> = [];
  listo_para_instalar: boolean;
  confirmado_por_cliente: boolean;
  reagendar: boolean;
  puede_conf_spin: boolean;
  agenda_fecha: any;
  agenda_color: any;
  pedidos: Array<any> = [];
  categoriaproyecto_id: number;
  categorias_producto: any;
  imagenes_incidencia_carpeta: Array<Fichero>;
  anio_construccion_vivienda: any;
  potencia_contratada: any;
  metros2_a_calefactar: any;
  numero_personas: any;

  constructor(data: Cliente) {
		if(data.id !== undefined) this.id = data.id;
		if(data.dni !== undefined) this.dni = data.dni;
		if(data.nombre !== undefined) this.nombre = data.nombre;
		if(data.apellidos !== undefined) this.apellidos = data.apellidos;
		if(data.ref !== undefined) this.ref = data.ref;
		if(data.email !== undefined) this.email = data.email;
		if(data.fecha_visita) this.fecha_visita = formatISO(parseISO(data.fecha_visita.replace(" ", "T")));
		if(data.fecha_instalacion_inicio) this.fecha_instalacion_inicio = formatISO(parseISO(data.fecha_instalacion_inicio.replace(" ", "T")));
		if(data.fecha_fase_planificacion) this.fecha_fase_planificacion = formatISO(parseISO(data.fecha_fase_planificacion.replace(" ", "T")));
		if(data.fecha_informefininstalacion) this.fecha_informefininstalacion = formatISO(parseISO(data.fecha_informefininstalacion.replace(" ", "T")));
    if (data.fecha_emisioninformevisita) this.fecha_emisioninformevisita = formatISO(parseISO(data.fecha_emisioninformevisita.replace(" ", "T")));
    if (data.fecha_instalacion_planificada && data.fecha_instalacion_planificada != "0000-00-00") this.fecha_instalacion_planificada = formatISO(parseISO(data.fecha_instalacion_planificada.replace(" ", "T")))
		if(data.fecha_concesionlicencia) this.fecha_concesionlicencia = formatISO(parseISO(data.fecha_concesionlicencia.replace(" ", "T")));
		if(data.fecha_desplazamientoincidencia) this.fecha_desplazamientoincidencia = formatISO(parseISO(data.fecha_desplazamientoincidencia.replace(" ", "T")));
		if(data.direccion !== undefined) this.direccion = data.direccion;
		if(data.municipio !== undefined) this.municipio = data.municipio;
    if(data.anio_construccion_vivienda !== undefined) this.anio_construccion_vivienda = data.anio_construccion_vivienda;
    if(data.potencia_contratada !== undefined) this.potencia_contratada = data.potencia_contratada;
    if(data.metros2_a_calefactar !== undefined) this.metros2_a_calefactar = data.metros2_a_calefactar;
    if(data.numero_personas !== undefined) this.numero_personas = data.numero_personas;
		if(data.provincia !== undefined) this.provincia = data.provincia;
		if(data.region !== undefined) this.region = data.region;
		if(data.cp !== undefined) this.cp = data.cp;
		if(data.modalidad_autoconsumo !== undefined) this.modalidad_autoconsumo = data.modalidad_autoconsumo;
		if(data.potencia_instalacion_estimada !== undefined) this.potencia_instalacion_estimada = data.potencia_instalacion_estimada;
		if(data.firmada_hoja_encargo !== undefined) this.firmada_hoja_encargo = data.firmada_hoja_encargo;
		if(data.interno !== undefined) this.interno = data.interno;
		if(data.gerente !== undefined) this.gerente = data.gerente;
		if(data.lat !== undefined) this.lat = data.lat;
		if(data.lon !== undefined) this.lon = data.lon;
		if(data.informe_visita !== undefined) this.informe_visita = data.informe_visita;
		if(data.informe_instalacion !== undefined) this.informe_instalacion = data.informe_instalacion;
		if(data.valor !== undefined) this.valor = data.valor;
		if(data.estadoslug !== undefined) this.estadoslug = data.estadoslug;
		if(data.estadolabel !== undefined) this.estadolabel = data.estadolabel;
		if(data.equipos !== undefined) this.equipos = data.equipos;
		if(data.disposiciones_imagenes !== undefined) this.disposiciones_imagenes = data.disposiciones_imagenes;
		if(data.disposicion !== undefined) this.disposicion = data.disposicion;
		if(data.tipo_instalacion !== undefined) this.tipo_instalacion = data.tipo_instalacion;
		if(data.tipo_cliente !== undefined) this.tipo_cliente = data.tipo_cliente;
		if(data.tipo_propiedad !== undefined) this.tipo_propiedad = data.tipo_propiedad;
		if(data.grua !== undefined) this.grua = data.grua;
		if(data.seleccionador !== undefined) this.seleccionador = data.seleccionador;
		if(data.comentario_instalador !== undefined) this.comentario_instalador = data.comentario_instalador;
		if(data.comentario_fecha_instalacion_inicio !== undefined) this.comentario_fecha_instalacion_inicio = data.comentario_fecha_instalacion_inicio;
		if(data.distribuidoras !== undefined) this.distribuidoras = data.distribuidoras;
		if(data.puede_agendar_instalacion !== undefined) this.puede_agendar_instalacion = data.puede_agendar_instalacion;
		if(data.desplazamientoincidencia !== undefined) this.desplazamientoincidencia = data.desplazamientoincidencia;
		if(data.historial_cliente !== undefined) this.historial_cliente = data.historial_cliente;
		if (data.documentos_cliente !== undefined) this.documentos_cliente = data.documentos_cliente.map((i: Fichero) => new Fichero(i));
		if(data.telefono !== undefined) this.telefono = data.telefono;
		if(data.puede_form_baterias !== undefined) this.puede_form_baterias = data.puede_form_baterias;
		if(data.puede_form_cargadores !== undefined) this.puede_form_cargadores = data.puede_form_cargadores;
		if(data.puede_form_visita !== undefined) this.puede_form_visita = data.puede_form_visita;
		if(data.gestiona_boletin !== undefined) this.gestiona_boletin = data.gestiona_boletin;
		if(data.doc_legalizacion_completa !== undefined) this.doc_legalizacion_completa = data.doc_legalizacion_completa;
		if(data.tiene_imagenes_legalizacion !== undefined) this.tiene_imagenes_legalizacion = data.tiene_imagenes_legalizacion;
		if(data.hace_incidencia !== undefined) this.hace_incidencia = data.hace_incidencia;
		if(data.hace_instalacion !== undefined) this.hace_instalacion = data.hace_instalacion;
		if(data.hace_montaje !== undefined) this.hace_montaje = data.hace_montaje;
		if(data.hace_visita !== undefined) this.hace_visita = data.hace_visita;
		if(data.hace_legalizacion !== undefined) this.hace_legalizacion = data.hace_legalizacion;
		if (data.informes_fin_instalacion !== undefined) this.informes_fin_instalacion = data.informes_fin_instalacion.map((i: Fichero) => new Fichero(i));
    if (data.informes_visita !== undefined) this.informes_visita = data.informes_visita.map((i: Fichero) => new Fichero(i));
    if (data.informes_incidencia !== undefined) this.informes_incidencia = data.informes_incidencia.map((i: Fichero) => new Fichero(i));
		if (data.imagenes_fin_instalacion !== undefined) this.imagenes_fin_instalacion = data.imagenes_fin_instalacion.map((i: Fichero) => new Fichero(i));
    if (data.imagenes_visita !== undefined) this.imagenes_visita = data.imagenes_visita.map((i: Fichero) => new Fichero(i));
    if (data.imagenes_incidencia !== undefined) this.imagenes_incidencia = data.imagenes_incidencia.map((i: Fichero) => new Fichero(i));
    if (data.imagenes_legalizacion !== undefined) this.imagenes_legalizacion = data.imagenes_legalizacion.map((i: Fichero) => new Fichero(i));
		if(data.clave_ente !== undefined) this.clave_ente = data.clave_ente;
    this.incidencias = (data.incidencias !== undefined) ? data.incidencias.map((item: any) => new Incidencia(item)) : [];
		if(data.listo_para_instalar !== undefined) this.listo_para_instalar = data.listo_para_instalar;
		if(data.confirmado_por_cliente !== undefined) this.confirmado_por_cliente = data.confirmado_por_cliente;
		if(data.reagendar !== undefined) this.reagendar = data.reagendar;
		if(data.puede_conf_spin !== undefined) this.puede_conf_spin = data.puede_conf_spin;
		if(data.pedidos !== undefined) this.pedidos = data.pedidos;
		if(data.categoriaproyecto_id !== undefined) this.categoriaproyecto_id = data.categoriaproyecto_id;
		if(data.categorias_producto !== undefined) this.categorias_producto = data.categorias_producto;
		if(data.imagenes_incidencia_carpeta !== undefined) this.imagenes_incidencia_carpeta = data.imagenes_incidencia_carpeta;
    return this;
  }

  getNombreCompl() {
    return this.nombre + " " + this.apellidos;
  }

  getFechaEstado(segm = "") {
    if (!segm) segm = this.estadoslug;
    if (this.getIncidenciaActiva()) return this.getIncidenciaActiva().fecha_desplazamiento;
    if (segm == EstadosClientes.planificacion) return this.fecha_fase_planificacion;
    if (segm == SegClientesListado.instalacion) return this.fecha_instalacion_inicio;
    if (segm == SegClientesListado.visita) return this.fecha_visita;
    return "";
  }

  comprobarPedidos(){
    if(this.estadoslug != EstadosClientes.planificacion && this.estadoslug != EstadosClientes.instalacion)
      return false;
    if(this.estadoslug == EstadosClientes.instalacion && this.fecha_informefininstalacion != null)
      return false;

    for (const pedido of this.pedidos) {
      if (pedido.fecha_entrega == null) 
        return true;
    }
    return false;
  }

  comprobarFechaInstalacionInicio(){
    const hoy = new Date();

    const fechaInstalacionInicio = new Date(this.fecha_instalacion_inicio);
    
    const diferenciaMilisegundos = fechaInstalacionInicio.getTime() - hoy.getTime();

    const diferenciaDias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

    return diferenciaDias <= 3;
  }

  getColor() {
    if (this.getIncidenciaActiva()) {
      return SegClientesListado.incidencia;
    }
    return this.estadoslug;
  }

  getUltimaFecha() {
    return this.fecha_instalacion_inicio || this.fecha_visita;
  }

  getUltimoEstado() {
    if (this.fecha_instalacion_inicio) return EstadosClientes.instalacion;
    return EstadosClientes.visita;
  }

  btnAgendarVisita() {
    return this.esVisita() && !this.fecha_visita;
  }

  btnAgendarInstalacion() {
    return this.esAgendarInstalacion();
  }

  btnInformeVisita() {
    return this.esVisita() && this.fecha_visita && format(new Date(this.fecha_visita), "Y-MM-dd") <= format(new Date(), "Y-MM-dd");
  }

  btnInformeInstalacion() {
    return this.estadoslug == EstadosClientes.instalacion && this.esInstalacionFlujo() && this.fecha_instalacion_inicio && !this.reagendar && format(new Date(this.fecha_instalacion_inicio), "Y-MM-dd") <= format(new Date(), "Y-MM-dd");
  }

  btnLegalizacion() {
    return this.estadoslug == EstadosClientes.legalizacion && this.gestiona_boletin && !this.doc_legalizacion_completa && !this.tiene_imagenes_legalizacion;
  }

  esVisita() {
    return (this.estadoslug == EstadosClientes.visita && !this.fecha_emisioninformevisita && this.hace_visita) || (this.esCandidato());
  }

  esCandidato() {
    return (this.estadoslug == EstadosClientes.candidato)
  }

  esInstalacionFlujo() {
    return ((this.estadoslug == EstadosClientes.instalacion && !this.fecha_informefininstalacion && (this.hace_instalacion || this.hace_montaje)) || (this.estadoslug == EstadosClientes.legalizacion))
  }

  esInstalacion() {
    return this.esPlantaFV();
  }

  esPlantaFV() {
    return (this.categoriaproyecto_id == 1);
  }

  esSetFV() {
      return (this.categoriaproyecto_id == 2);
  }

  esAerotermia() {
    return (this.categoriaproyecto_id == 3);
  }
  
  esAislada() {
    return (this.categoriaproyecto_id == 4);
  }

  esAmpliacion() {
    return (this.esSetFV() && this.categorias_producto?.includes('placa'));
  }

  esBateria(solo_bateria = false) {
    if (solo_bateria && this.esAmpliacion()) return false;
    return (this.esSetFV() && this.categorias_producto?.includes('bateria'));
  }

  esCargadorCoche(solo_cargador = false) {
    if (solo_cargador && this.esAmpliacion()) return false;
    return (this.esSetFV() && this.categorias_producto?.includes('coche'));
  }

  esBateriaOCargadorCoche(solo_bateria_o_cargador=false)
    {
        if(solo_bateria_o_cargador && this.esAmpliacion()) {
            return false;
        }
        if(this.esBateria(false) || this.esCargadorCoche(false)) {
            return true;
        }
        return false;
    }

  esIncidencia() {
    return (this.getIncidenciaActiva()?.id)
  }

  getIncidenciaActiva() : Incidencia{
    return this.incidencias.find( i => (i.esAgendar() || i.esInforme() || i.esPendConfirmacionCli()) && !i.incidencia_resuelta);
  }

  esIncidenciaInforme() {
    return this.getIncidenciaActiva()?.esInforme();
  }

  esIncidenciaAgendar() {
    return this.getIncidenciaActiva()?.esAgendar();
  }

  esProvicional() {
    return this.fecha_instalacion_planificada && !this.fecha_instalacion_inicio;
  }

  esAgendarVisita(){
    return (!this.fecha_visita && this.hace_visita && this.estadoslug == EstadosClientes.visita)
  }

  esPendienteFirmaHojaEncargo(){
    return (!this.esAerotermia() && !this.firmada_hoja_encargo && this.hace_instalacion && !this.interno && this.gerente && [EstadosClientes.planificacion, EstadosClientes.preparacion, EstadosClientes.instalacion].includes(this.estadoslug) )
  }

  esInformeVisita() {
    return (this.esVisita() && (this.fecha_visita && format(new Date(this.fecha_visita), "Y-MM-dd") <= format(new Date(), "Y-MM-dd")) && !this.fecha_emisioninformevisita && this.hace_visita && this.estadoslug == EstadosClientes.visita)
  }

  esAgendarInstalacion(){
    return (this.estadoslug == EstadosClientes.planificacion && this.fecha_concesionlicencia && this.fecha_instalacion_planificada && (this.hace_instalacion || this.hace_montaje) && ((!this.fecha_instalacion_inicio && this.puede_agendar_instalacion) || this.reagendar))
  }

  esInformeInstalacion() {
    return (this.esInstalacionFlujo() && (this.fecha_instalacion_inicio && format(new Date(this.fecha_instalacion_inicio), "Y-MM-dd") <= format(new Date(), "Y-MM-dd")) && this.estadoslug == EstadosClientes.instalacion && !this.fecha_informefininstalacion && (this.hace_instalacion || this.hace_montaje) && !this.esIncidencia() && !this.reagendar);
  }

  esLegalizacion() {
    return this.estadoslug == EstadosClientes.legalizacion;
  }

  tieneIncidencia() {
    return "incidencia";
  }

  esSoty() {
    return ['soty_es', 'soty_pt', 'soty'].includes(this.clave_ente);
  }

  esTotal() {
    return this.clave_ente == 'total';
  }

  esMediaMarkt() {
    return this.clave_ente == 'mediamarkt';
  }

  esInterno() {
    return this.interno;
  }

  getEquipoXTipo(tipo) {
    let res =  this.equipos.filter(e => {
      if (tipo == "equipo") return !["placa", "estructura"].includes(e.clave_tipo);
      return e.clave_tipo == tipo;
    })
    return res;
  }

  getTipoInstalacion(){
    if(this.esAerotermia()) return 'Aerotermia';
    if(this.esAislada()) return 'Aislada';
    if(this.esBateria()) return 'Bateria';
    if(this.esCargadorCoche()) return 'Cargador coche';
    if(this.esAmpliacion()) return 'Ampliacion';
    if(this.esInstalacion()) return 'Placas solares';
    
    return '';
  }

}
