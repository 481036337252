import { Directive, Input, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: 'ion-select[SelectPopover]',
  host: {
    '(click)': 'clickSelect()',
   }
})
export class SelectPopoverDirective {
  @Input('SelectPopover') selectPopover: string;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {
  }

  clickSelect() {
    console.log(this.el.nativeElement);

    const select = document.querySelector('ion-select-popover');
    console.log(select);
    select.removeAttribute('popover');
    if (this.el.nativeElement.hasAttribute('interface')) {
      console.log("sii")
      //this.renderer.removeAttribute(this.el.nativeElement, 'interface');
    } else {
      console.log("noo")
    }

  }

  /* onLoad() {
    this.renderer.removeClass(this.el.nativeElement, 'img-loader');
  }

  onError() {
    this.renderer.removeClass(this.el.nativeElement, 'img-loader');
    switch (this.selectPopover) {
      case ImgTipoError.usuarios:
        this.renderer.setAttribute(this.el.nativeElement, 'src', 'assets/default/150x150-usuarios.png');
        break;

      default:
        this.renderer.setStyle(this.el.nativeElement, "display", "none");
        break;
    }
  } */

}
