import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FirebaseAnalyticsService } from '../firebase-analytics/firebase-analytics.service';
import { GeneralObsService } from '../observables/general-obs.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class NavegacionService {
  private paramsSubject = new Subject<any>();
  public params: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtl: NavController,
    private storageService: StorageService,
    private firebaseAnalyticsService: FirebaseAnalyticsService
  ) {
  }

  goTo(url, data = {}, extras: NavigationExtras = {}) {
    this.firebaseAnalyticsService.setCurrentScreen(url);
    if (Object.keys(data).length !== 0) {
      data = Object.assign({}, data);
      data = this.cleanIn(data);
      let keyUrl = url.replace(/\//g , "");
      this.params[keyUrl] = window.btoa(unescape(encodeURIComponent(JSON.stringify(data))))
      this.storageService.set("dnav-prosoty", this.params);
    }
    return this.router.navigate([url], extras);
  }

  goToRoot(url) {
    this.firebaseAnalyticsService.setCurrentScreen(url);
    return this.navCtl.navigateRoot(url, {replaceUrl: true});
  }

  back() {
    let keyUrl = decodeURIComponent(this.getCurrentUrl()).replace(/\//g, "");
    delete this.params[keyUrl];
    this.storageService.set("dnav-prosoty", this.params);
    this.navCtl.back();
  }

  pop() {
    this.navCtl.pop();
  }

  async getParams() {
    let paramsAtob;
    let keyUrl = decodeURIComponent(this.getCurrentUrl()).replace(/\//g, "");
    let paramsCurrenUrl = this.params[keyUrl];
    if (paramsCurrenUrl) {
      paramsAtob = JSON.parse(decodeURIComponent(escape(atob(paramsCurrenUrl))))
    }
    let params = Object.assign({}, paramsAtob);
    return this.cleanOut(params);
  }

  getRouterNavidated() {
    return this.router.navigated;
  }

  getCurrentUrl() {
    return this.router.url;
  }

  getParamsSubs(): Subject<any> {
    this.route.queryParams.subscribe((params) => {
      if (params['dt']) {
        params = JSON.parse(decodeURIComponent(escape(atob(params['dt']))))
      }
      params = Object.assign({}, params);
      this.paramsSubject.next(this.cleanOut(params));
    });
		return this.paramsSubject;
  }

  private cleanIn(obj) {
    var objKeys = Object.keys(obj);
    objKeys.forEach((key) => {
        var keyValOut = obj[key];
        if (keyValOut instanceof Object) {
          obj[key] = JSON.stringify(obj[key]);
          obj[key] = this.cleanIn(obj[key]);
        }
    })
    return obj;
  }

  private cleanOut(obj) {
    var objKeys = Object.keys(obj);
    objKeys.forEach((key) => {
      var keyValOut = obj[key];
      if (typeof keyValOut == "string" && (keyValOut.charAt(0) == "{" || keyValOut.charAt(0) == "[")) {
        obj[key] = JSON.parse(obj[key]);
        obj[key] = this.cleanOut(obj[key]);
      }
    })
    return obj;
  }

}
