import { Roles } from '../enums/Roles';
import { IUsuario } from '../interfaces/IUsuario';

export class Usuario implements IUsuario {
  id: number;
  rol: Roles;
  nombre: string;
  apellidos: string;
  email: string;
  onesignal_player: string;
  dni: string;
  telefono: string;
  ciudad: string;
  direccion: string;
  app_token: string;
  trabajador_soty: boolean;

  constructor(data: Usuario) {
		if(data.id !== undefined) this.id = data.id;
		if(data.rol !== undefined) this.rol = data.rol;
		if(data.nombre !== undefined) this.nombre = data.nombre;
		if(data.apellidos !== undefined) this.apellidos = data.apellidos;
		if(data.email !== undefined) this.email = data.email;
		if(data.dni !== undefined) this.dni = data.dni;
		if(data.telefono !== undefined) this.telefono = data.telefono;
		if(data.ciudad !== undefined) this.ciudad = data.ciudad;
		if(data.direccion !== undefined) this.direccion = data.direccion;
		if(data.app_token !== undefined) this.app_token = data.app_token;
    if(data.trabajador_soty !== undefined) this.trabajador_soty = data.trabajador_soty;
    return this;
  }

  getNombreCompl() {
    return this.nombre + " " + this.apellidos;
  }

  canRole(roles: Array<Roles>) {
    return roles.includes(this.rol);
  }

}