import {
  Directive,
  ElementRef,
  HostBinding
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[datetime-text]',
  standalone: false,
})
export class DatetimeTextDirective {

  constructor(
    private translate: TranslateService,
    private elementRef: ElementRef
  ) {
    //this.elementRef.nativeElement.insertAdjacentHTML('beforeend','<div slot="title">'+this.translate.instant("ion-datetime.title-text")+'</div><div slot="time-label">'+this.translate.instant("ion-datetime.time-text")+'</div>');
  }

  @HostBinding('attr.locale') locale: string = this.translate.getBrowserCultureLang() || "en-US";
  
  //@HostBinding('attr.show-default-buttons') showDefaulButtons: string = "true";
  //@HostBinding('attr.show-cancel-button') showCancelButtons: string = "true";
  //@HostBinding('attr.show-clear-button') showClearButtons: string = "true";
  //@HostBinding('attr.show-default-time-label') showDefaulTimeLabel: string = "false";
  //@HostBinding('attr.show-default-title') showDefaulTitle: string = "true";
    
  @HostBinding('attr.clear-text') clearText: string = this.translate.instant(
    "ion-datetime.clear-text"
  );
  @HostBinding('attr.cancel-text') cancelText: string = this.translate.instant(
    "ion-datetime.cancel-text"
  );
  @HostBinding('attr.done-text') doneText: string = this.translate.instant(
    "ion-datetime.done-text"
  );
}
