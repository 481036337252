// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  height: 100%;
}

.btn-cerrar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  color: white;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/modales/modal-informativa/modal-informativa.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,MAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,eAAA;AACJ","sourcesContent":["ion-card{\n    height: 100%;\n}\n\n.btn-cerrar{\n    position: absolute;\n    top: 0;\n    right: 0;\n    z-index: 1;\n    color: white;\n    font-size: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
