export const environment = {
    production: true,
    api_url: "https://api.sotysolar.es/api/app/v1/",
    name_app: "ProSoty",
    app_name_slug: "instaladoresapp",
    version_android: "1.0.64",
    version_codeAndroid: 1,
    version_ios: "1.0.64",
    version_code_ios: 7,
    usu_storage: "usu-inst-soty",
    cli_storage: "cli-inst-soty",
    cli_form_visita_storage: "clientes-form-visita-soty",
    cli_form_instalacion_storage: "clientes-form-instalacion-soty",
    cli_form_incidencia_storage: "clientes-form-incidencia-soty",
    api_url_api_spin: "http://192.168.1.11:3000/",
    api_url_socket: "https://spin.soty.dev/",
};
