import { Injectable } from '@angular/core';
import { Usuario } from 'src/app/modelos/usuario.model';

@Injectable({
  providedIn: 'root'
})

export class UsuarioService{
  public usu: Usuario;
  public onesignalPlayerId: string = "";
  
  constructor(
  ) {
  }

  statusSendOnesignal(): boolean {
		if (this.onesignalPlayerId == "" || (this.usu?.onesignal_player && this.onesignalPlayerId == this.usu.onesignal_player)) {
			return false;
		}
		return true;
  }

}
