// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slides {
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
}
.slides swiper-slide {
  margin: auto;
  width: 100%;
}
.slides swiper-slide img {
  width: 100%;
}

.close-modal {
  position: absolute;
  color: white;
  top: 10px;
  right: 50px;
  width: 10px;
  height: 40px;
  z-index: 2;
  text-align: center;
}
.close-modal ion-button {
  font-size: 22px;
}

.ios .close-modal {
  top: 30px;
  right: 50px;
}

.share-img {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 32px;
  z-index: 20;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/modales/modal-ver-imagen/modal-ver-imagen.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AACJ;AACI;EACE,YAAA;EACA,WAAA;AACN;AAAM;EACI,WAAA;AAEV;;AAGA;EACI,kBAAA;EACA,YAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,kBAAA;AAAJ;AACI;EACI,eAAA;AACR;;AAII;EACI,SAAA;EACA,WAAA;AADR;;AAOA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,WAAA;AAJJ","sourcesContent":[".slides{\n    background-color: black;\n    width: 100%;\n    height: 100%;\n    display: flex;\n\n    swiper-slide{\n      margin: auto;\n      width: 100%;\n      img{\n          width: 100%;\n      }\n    }\n}\n\n.close-modal {\n    position: absolute;\n    color: white;\n    top: 10px;\n    right: 50px;\n    width: 10px;\n    height: 40px;\n    z-index: 2;\n    text-align: center;\n    ion-button{\n        font-size: 22px;\n    }\n}\n\n.ios{\n    .close-modal{\n        top: 30px;\n        right: 50px;\n\n    }\n}\n\n\n.share-img{\n    position: absolute;\n    bottom: 10px;\n    right: 10px;\n    font-size: 32px;\n    z-index: 20;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
