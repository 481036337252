export enum Roles {
  admin = "admin",
  agente = "agente",
  callcenter = "callcenter",
  coordinador = "coordinador",
  editor = "editor",
  rolgerente = "rolgerente",
  rolsoty = "rolsoty",
  roltrabajador = "roltrabajador",
  suscriptor = "suscriptor",

}

