import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import es from '@angular/common/locales/es';
import en from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es" },
    //{ provide: LOCALE_ID, useValue: window.navigator.language },
  ],
  exports: [TranslateModule]
})
export class I18nModule {
  constructor(translate: TranslateService) {
    let loc = "es";
    translate.setDefaultLang(loc);
    registerLocaleData(es);
    /* let loc = translate.getBrowserLang() || "en";
    translate.setDefaultLang(loc);
    translate.use(loc);
    registerLocaleData(es);
    registerLocaleData(en); */
  }
}