import { formatISO, parseISO } from 'date-fns';
import { IIncidencia } from '../interfaces/IIncidencia';
import { Fichero } from './fichero.model';

export class Incidencia implements IIncidencia {
  id: number;
  categoria: string;
  confirmado_por_cliente: boolean;
  descripcion_problema: string;
  fecha_desplazamiento: string;
  created_at: string;
  id_hijo: number;
  incidencia_resuelta: boolean;
  necesita_desplazamiento_incidencia: boolean;
  nombre_responsabe: string;
  repercute_instalador: boolean;
  tipo_incidencia: string;
  departamento: string;
  relevancia?: number;
  comentario_fecha_desplazamiento?: string;
  imagenes?: Array<Fichero>;
  reagendar_cliente?: boolean;
  cierre_registro?: string;

  constructor(data: Incidencia) {
    if (data.id !== undefined) this.id = data.id;
    if (data.categoria !== undefined) this.categoria = data.categoria;
    if (data.confirmado_por_cliente !== undefined)
      this.confirmado_por_cliente = data.confirmado_por_cliente;
    if (data.descripcion_problema !== undefined)
      this.descripcion_problema = data.descripcion_problema;
    if (data.fecha_desplazamiento)
      this.fecha_desplazamiento = formatISO(
        parseISO(data.fecha_desplazamiento.replace(' ', 'T'))
      );
    if (data.id_hijo !== undefined) this.id_hijo = data.id_hijo;
    if (data.incidencia_resuelta !== undefined)
      this.incidencia_resuelta = data.incidencia_resuelta;
    if (data.necesita_desplazamiento_incidencia !== undefined)
      this.necesita_desplazamiento_incidencia =
        data.necesita_desplazamiento_incidencia;
    if (data.nombre_responsabe !== undefined)
      this.nombre_responsabe = data.nombre_responsabe;
    if (data.repercute_instalador !== undefined)
      this.repercute_instalador = data.repercute_instalador;
    if (data.tipo_incidencia !== undefined)
      this.tipo_incidencia = data.tipo_incidencia;
    if (data.departamento !== undefined) this.departamento = data.departamento;
    if (data.relevancia !== undefined) this.relevancia = data.relevancia;
    if (data.comentario_fecha_desplazamiento !== undefined) this.comentario_fecha_desplazamiento = data.comentario_fecha_desplazamiento;
    if (data.created_at !== undefined){
      this.created_at = formatISO(parseISO(data.created_at.replace(' ', 'T')));
    } 
    if(data.imagenes !== undefined && data.imagenes.length > 0){
      this.imagenes = [];
      data.imagenes.forEach( (el) => {
        this.imagenes.push(new Fichero(el));
      });
    }
    if(data.reagendar_cliente !== undefined){
      this.reagendar_cliente = data.reagendar_cliente;
    }
    if(data.cierre_registro !== undefined){
      this.cierre_registro = data.cierre_registro;
    }
    return this;

  }

  esAgendar() {
    return ( (!this.fecha_desplazamiento || this.reagendar_cliente) && !this.cierre_registro);
  }

  esInforme() {
    return this.fecha_desplazamiento && !this.reagendar_cliente;
  }

  esPendConfirmacionCli() {
    return this.fecha_desplazamiento && !this.confirmado_por_cliente;
  }

  esConfirmadoPorCliente(){
    let confirmado = (this.confirmado_por_cliente) ? "Si":"No";
    return confirmado;
  }
}
