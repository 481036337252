import { Component } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { OnesignalService } from './servicios/onesignal/onesignal.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { ApiService } from './servicios/api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseAnalyticsService } from './servicios/firebase-analytics/firebase-analytics.service';
import { register } from 'swiper/element/bundle';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment.prod';

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private apiService: ApiService,
    private platform: Platform,
    private onesignalService: OnesignalService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private translate: TranslateService,
    private http: HttpClient,
    private alertController: AlertController
  ) {
    this.platform.ready().then(() => {
      /* OneSignal */
      this.checkAppVersion();
      this.onesignalService.setApiService(this.apiService);
      this.onesignalService.init();
      if (this.platform.is("desktop")) {
        /* Firebase */
        this.firebaseAnalyticsService.init();
      }
      setTimeout(() => {
        SplashScreen.hide();
      }, 1000);
    })

    let lang = window.navigator.language;
    if(lang.indexOf('es') >= 0 ){
      this.translate.setDefaultLang('es');
    }else if(lang.indexOf('pt') >= 0 ){
      this.translate.setDefaultLang('pt');
    }else if(lang.indexOf('en') >=0 ){
      this.translate.setDefaultLang('en');
    }else{
      this.translate.setDefaultLang('es');
    }
  }

  ngOnInit() {
  }

  checkAppVersion() {

    this.apiService.get('version', {}).subscribe(
      (res) => {
        const serverVersion = res.version;
        const appVersion = this.platform.is('android') ? environment.version_android : environment.version_ios;

        if (serverVersion !== appVersion) {
          this.showUpdateAlert();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  async showUpdateAlert() {
    const alert = await this.alertController.create({
      header: 'Nueva versión disponible',
      message: 'Hay una nueva versión de la app disponible. Por favor, actualiza para continuar.',
      buttons: [
        {
          text: 'Actualizar',
          handler: () => {
            this.redirectToStore();
          }
        }
      ]
    });
    await alert.present();
  }

  redirectToStore() {
    if (this.platform.is('android')) {
      window.open('https://play.google.com/store/apps/details?id=es.sotysolar.instaladoresapp', '_system');
    } else if (this.platform.is('ios')) {
      window.open('https://apps.apple.com/app/id1627519999', '_system');
    }
  }

}
