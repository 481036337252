export enum EstadosClientes {
  borrador = "borrador",
  candidato = "candidato",
  visita = "visita",
  preparacion = "preparacion",
  planificacion = "planificacion",
  instalacion = "instalacion",
  legalizacion = "legalizacion",
  finalizacion = "finalizacion",
  cancelado = "cancelado",
}