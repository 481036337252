import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CommonDirectivesModule } from '../directivas/common-directives.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavMenuComponent } from './navegacion/nav-menu/nav-menu.component';
import { NavHeaderComponent } from './navegacion/nav-header/nav-header.component';
import { ModalInformativaComponent } from './modales/modal-informativa/modal-informativa.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorsComponent } from './formularios/form-errors/form-errors.component';
import { FormAgregarImagenComponent } from './formularios/form-agregar-imagen/form-agregar-imagen.component';
import { FormProgressBarComponent } from './formularios/form-progress-bar/form-progress-bar.component';
import { ModalFirmaComponent } from './modales/modal-firma/modal-firma.component';
import { ModalVerImagenComponent } from './modales/modal-ver-imagen/modal-ver-imagen.component';

import { ScrollingModule } from '@angular/cdk/scrolling';


const components = [
  NavMenuComponent,
  NavHeaderComponent,

  ModalInformativaComponent,
  ModalFirmaComponent,
  ModalVerImagenComponent,

  FormErrorsComponent,
  FormAgregarImagenComponent,
  FormProgressBarComponent,
]

@NgModule({
    declarations: components,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        IonicModule,
        CommonModule,
        TranslateModule,
        CommonDirectivesModule,
        FormsModule,
        ReactiveFormsModule,
        ScrollingModule,
    ],
    exports: [...components, [ScrollingModule]]
})


export class  ComponentesCompartidosModule { }
