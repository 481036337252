import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AutenticacionService } from 'src/app/servicios/autenticacion/autenticacion.service';
import { NavegacionService } from 'src/app/servicios/navegacion/navegacion.service';
import { StorageService } from 'src/app/servicios/storage/storage.service';
 
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
 
  constructor(
    public auth: AutenticacionService,
    private storageService: StorageService,
    private navegacionService: NavegacionService,
    private menuController: MenuController,
  ) {
    }

 
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    this.storageService.get("dnav-prosoty").then((params) => {
      if (params && Object.keys(this.navegacionService.params).length == 0) {
        this.navegacionService.params = params;
      }
    })
    return this.auth.checkToken().then(async (status) => {
      if (!status) {
        this.menuController.enable(false, "first");
        this.navegacionService.goTo("login");
        return false;
      }
      return true;
    });
  }

}