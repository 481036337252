<ion-header>
  <ion-toolbar>
    <ion-title class="ion-text-center">{{ "nav-menu.titulo-menu" | translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list id="inbox-list">
  
    <ion-menu-toggle auto-hide="false" (click)="onIr('/tabs/usu-mi-perfil')">
      <ion-item lines="none" detail="false">
        <ion-icon slot="start" name="icon-inicio"></ion-icon>
        <ion-label>{{ "tabs.txt-inicio" | translate}}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle auto-hide="false" (click)="onIr('tabs/usuario-perfil')">
      <ion-item lines="none" detail="false">
        <ion-icon slot="start" name="person-outline"></ion-icon>
        <ion-label>{{ "tabs.txt-perfil" | translate}}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle auto-hide="false" (click)="onIr('solicitud-material-ferreteria')"
      *ngIf="esInterno()"
      >
      <ion-item lines="none" detail="false">
        <ion-icon slot="start" name="bag-add-outline"></ion-icon>
        <ion-label>{{ "nav-menu.txt-solicitud-material-ferreteria" | translate}}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle auto-hide="false" (click)="onIr('solicitudes-material-ferreteria-realizadas')"
      *ngIf="esInterno()"
      >
      <ion-item lines="none" detail="false">
        <ion-icon slot="start" name="bag-outline"></ion-icon>
        <ion-label>{{ "nav-menu.txt-solicitudes-material-ferreteria-realizadas" | translate}}</ion-label>
      </ion-item>
    </ion-menu-toggle>
   
    <ion-menu-toggle auto-hide="false" (click)="onAbrirBateriaAR()">
      <ion-item lines="none" detail="false">
        <ion-icon slot="start" name="logo-apple-ar"></ion-icon>
        <ion-label>{{ "nav-menu.txt-bateria-ar" | translate}}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle auto-hide="false" (click)="onIr('ayuda-preguntas-frecuentes')">
      <ion-item lines="none" detail="false">
       <ion-icon slot="start" name="help-circle"></ion-icon>
       <ion-label>{{ "nav-menu.txt-preguntas-frecuentes" | translate}}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle auto-hide="false" (click)="logout()">
      <ion-item lines="none" detail="false">
        <ion-icon slot="start" name="log-out-outline"></ion-icon>
        <ion-label>{{ "nav-menu.txt-salir" | translate}}</ion-label>
      </ion-item>
    </ion-menu-toggle>
  </ion-list>
  
  <p class="ion-text-center version">
    <ng-container *ngIf="platform.is('ios')">
      V. {{env.version_ios}}
    </ng-container>
    <ng-container *ngIf="!platform.is('ios')">
      V. {{env.version_android}}
    </ng-container>
  </p>
</ion-content>
