import { formatISO, parseISO } from 'date-fns';
import { IFichero } from '../interfaces/IFichero';

export class Fichero implements IFichero {
  visibilidad?: string;
  nombre?: string;
  type: string;
  fecha: string;
  ruta: string;
  link_externo?: boolean;

  constructor(data: Fichero) {
    if(data.visibilidad !== undefined) this.visibilidad = data.visibilidad;
    if(data.nombre !== undefined) this.nombre = data.nombre;
    if(data.type !== undefined) this.type = data.type;
    if(data.fecha !== undefined) this.fecha = formatISO(parseISO(data.fecha.replace(" ", "T")));
		if(data.ruta !== undefined) this.ruta = data.ruta;
		if(data.link_externo !== undefined) this.link_externo = data.link_externo;
    return this;
  }

}