<ion-content>
  <ion-button class="btn-cerrar" (click)="onCerrar()" expand="block" fill="clear" shape="round" size="small">
    <ion-icon name="close-circle"></ion-icon>
  </ion-button>
  <ion-card class="ion-no-margin">
    <img src="https://picsum.photos/900/500" />
    <ion-card-header>
      <ion-card-subtitle>Destination</ion-card-subtitle>
      <ion-card-title>Madison, WI</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      Founded in 1829 on an isthmus between Lake Monona and Lake Mendota, Madison was named the capital of the
      Wisconsin Territory in 1836.
    </ion-card-content>
  </ion-card>
</ion-content>