import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Browser } from '@capacitor/browser';
import { Platform } from '@ionic/angular';
import { Roles } from 'src/app/enums/Roles';
import { AutenticacionService } from 'src/app/servicios/autenticacion/autenticacion.service';
import { NavegacionService } from 'src/app/servicios/navegacion/navegacion.service';
import { ClienteService } from 'src/app/servicios/singleton/cliente.service';
import { UsuarioService } from 'src/app/servicios/singleton/usuario.service';
import { StorageService } from 'src/app/servicios/storage/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  standalone: false,
})
export class NavMenuComponent implements OnInit {

  public Roles = Roles;
  public env = environment;
  lang: string;
  public configuracionForm: FormGroup;

  constructor(
    private navegacionService: NavegacionService,
    public usuService: UsuarioService,
    public clienteService: ClienteService,
    private autenticacionService: AutenticacionService,
    public platform: Platform,
    private storageService: StorageService,
    public formBuilder: FormBuilder,
  ) {
    this.configuracionForm = this.formBuilder.group({
      modo_dark: [false]
    });
  }

  async ngOnInit() {
    await this.loadDarkModePreference();
    this.lang = window.navigator.language;
  }

  esInterno(){
    return this.clienteService?.clientes[0]?.interno;
  }

  onAbrirBateriaAR() {
    Browser.open({url: "https://sotysolar.es/bateria-ar"});
  }

  onIr(url: string) {
    this.navegacionService.goTo(url);
  }

  logout() {
    this.autenticacionService.logout();
  }

  async toggleDarkMode(event: any) {
    const checked = event.detail.checked;
    
    document.body.classList.toggle('dark', checked);
    if (this.usuService.usu) {
      this.usuService.usu.modo_dark = checked;
    }
    this.configuracionForm.patchValue({ modo_dark: checked });
    await this.storageService.set('modo_dark', checked);
  }

  async loadDarkModePreference() {
    try {
      const savedDarkMode = await this.storageService.get('modo_dark');
      const isDarkMode = savedDarkMode ?? false;

      document.body.classList.toggle('dark', isDarkMode);

      if (this.usuService.usu) {
        this.usuService.usu.modo_dark = isDarkMode;
      }

      this.configuracionForm.patchValue({ modo_dark: isDarkMode });

    } catch (error) {
      console.error("Error al cargar la preferencia de modo oscuro:", error);
    }
  }

  get modo_dark() {
    return this.configuracionForm.get('modo_dark');
  }
}
