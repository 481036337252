<ion-header class="header" mode="md">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button *ngIf="menu" fill="clear" color="primary" (click)="onMenu()">
        <ion-icon name="menu-outline"></ion-icon>
      </ion-button>
      <ion-button  class="btn-back" *ngIf="volver" fill="clear" color="primary" (click)="onVolver()">
        <ion-icon [color]="color_invertido ? 'light' : 'primary'" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-img 
      [src]="isDarkMode ? '/assets/img/sotysolar_blanco.svg' : '/assets/img/sotysolar.svg'"
      class="logo-soty">
    </ion-img>
    <ion-buttons slot="start">
      <ion-button fill="clear" [disabled]="true" color="primary">
       <!--  <ion-icon *ngIf="menu" name="warning-outline"></ion-icon> -->
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ng-container *ngTemplateOutlet="selectTemplate">
  </ng-container>
  <ion-title *ngIf="selectTemplate && titulo" class="titulo ion-text-center">{{titulo | titlecase}}</ion-title>
  <ion-segment (ionChange)="onSegmSel($event)" mode="ios" [value]="segmSel" *ngIf="segmentaciones.length > 0">
    <ion-segment-button *ngFor="let seg of segmentaciones" [value]="seg.slug">
      <ion-label>{{seg.titulo}}</ion-label>
    </ion-segment-button>
  </ion-segment>
</ion-header>
