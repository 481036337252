import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

const store = new Storage();

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { 
    this.init();
  }

  async init() {
    await store.create();
  }

  // CREATE
  set(nameObj: string, item: any): Promise<any> {
    return store.get(nameObj).then((items: any[]) => {
      if (items && items.length > 0 && Array.isArray(items)) {
        items.push(item);
        return store.set(nameObj, items);
      } else {
        return store.set(nameObj, item);
      }
    });
  }

  // READ
  get(nameObj: string): Promise<any> {
    return store.get(nameObj);
  }

  // UPDATE
  update(nameObj: string, item: any): Promise<any> {
    return store.get(nameObj).then((items: any[]) => {
      if (!items || items.length === 0) {
        return null;
      }

      let newItems: any[] = [];

      for (let i of items) {
        if (i.id === item.id) {
          newItems.push(item);
        } else {
          newItems.push(i);
        }
      }

      return store.set(nameObj, newItems);
    });
  }

  // DELETE
  remove(nameObj: string): Promise<any> {
    return store.remove(nameObj);
  }
}