<!-- 
<div class="custom-splash" *ngIf="showSplash">
  <div class="splash-content">
    <lottie-player
      src="assets/animaciones/demo/data.json"
      background="transparent"
      speed="1"
      style="width: 100%; height: 100%"
      loop
      autoplay>
    </lottie-player>
  </div>
</div> -->

<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" menuId="first" swipeGesture="false" side="end">
        <app-nav-menu></app-nav-menu>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
  <div class="custom-loading hide">
    <ion-spinner name="bubbles" color="secondary"></ion-spinner>
    <p class="text"></p>
  </div>
</ion-app>
