import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Browser } from "@capacitor/browser";
//import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { AlertasService } from '../alertas/alertas.service';
import { TranslateService } from '@ngx-translate/core';

const CACHE_FOLDER = "CACHED_IMG";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private http: HttpClient,
    private alertasService: AlertasService,
    private translateService: TranslateService,
    //private previewAnyFile: PreviewAnyFile,
  ) { }

  async previewFile(url) {
    /* if (this.platform.is('hybrid')) {
      this.previewAnyFile.preview(url)
        .then((res: any) => console.log(res))
        .catch((error: any) => console.error(error));
    } else {
    } */
    Browser.open({ url: url })
  }

  downloadFile(url, customName = "") {
    this.alertasService.presentLoading();
    this.http.get(url, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events',
    }).subscribe(async event => {
      if (event.type == HttpEventType.Response) {
        const name = (customName) ? customName:  url.substr(url.lastIndexOf('/') + 1)
        const base64 = await this.blobToBase64(event.body) as string;
        await Filesystem.writeFile({
          path: name,
          data: base64,
          directory: Directory.Documents,
          recursive: true
        })
        this.alertasService.presentToast(this.translateService.instant("file-services.txt-guardo-correctamente-archivo", {nombre_archivo: name}), 4000);
        this.alertasService.closePresentLoading();
      }
    }, err => {
      this.alertasService.closePresentLoading();
    })
  }

  blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  async writeSecretFile(nameFile, data) {
      await Filesystem.writeFile({
        path: 'secrets-tap/' + nameFile,
        data: data,
        directory: Directory.External,
        encoding: Encoding.UTF8,
        recursive: true
      });
  };
  
  async readSecretFile(nameFile) {
    const contents = await Filesystem.readFile({
      path: 'secrets-tap/'+nameFile,
      directory: Directory.External,
      encoding: Encoding.UTF8,
    });
    return contents;
  };

  async deleteSecretFile(nameFile){
    await Filesystem.deleteFile({
      path: 'secrets-tap/'+nameFile,
      directory: Directory.External,
    });
  };

  async clearCache() {

    await Filesystem.rmdir({
      directory: Directory.Cache,
      path: CACHE_FOLDER,
      recursive: true
    })
  }

  private async readFileBlob(file: any) {
    return new Promise<any>((resolve, reject) => {
      file.arrayBuffer().then((arrayBuffer) => {
        let fileBlob = new Blob([new Uint8Array(arrayBuffer)], {type: file.type });
        const dataFile = {
          fileBlob : fileBlob,
          filename : file.name
        };
        resolve(dataFile);
      }).catch((err) => reject(err));
    })
  }

  public async normalizeFiles(files) {
    return new Promise<any>(async (resolve, reject) => {
      let filesRes = [];
      for (let file of files) {
        let fileData = await this.readFileBlob(file);
        fileData.fileLocal = URL.createObjectURL(fileData.fileBlob);
        filesRes.push(fileData);
      }
      resolve(filesRes);
    })
  }
}
