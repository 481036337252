// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titulo_progress_bar {
  color: #262626;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.1px;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/formularios/form-progress-bar/form-progress-bar.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,qBAAA;EACA,yBAAA;AACJ","sourcesContent":[".titulo_progress_bar{\n    color: #262626;\n    text-align: center;\n    font-size: 13px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    letter-spacing: 1.1px;\n    text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
