// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  //api_url: "http://soty.local:89/api/app/v1/",
  //api_url: "http://172.20.10.3:89/api/app/v1/",
  //api_url: "http://soty.local/api/app/v1/",
  api_url: "https://preprod.soty.dev/api/app/v1/",
  //api_url: "https://qa.soty.dev/api/app/v1/",
  //api_url: "http://192.168.0.4:88/api/app/v1/",
  name_app: "ProSoty",
  app_name_slug: "instaladoresapp",
  version_android: "1.1.74",
  version_codeAndroid: 1,
  version_ios: "1.1.74",
  version_code_ios: 7,
  usu_storage: "usu-inst-soty",
  cli_storage: "cli-inst-soty",
  cli_form_visita_storage: "clientes-form-visita-soty",
  cli_form_instalacion_storage: "clientes-form-instalacion-soty",
  cli_form_incidencia_storage: "clientes-form-incidencia-soty",
  api_url_api_spin: "http://192.168.1.11:3000/",
  api_url_socket: "https://spin.soty.dev/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
