import { UsuarioService } from '../singleton/usuario.service';
import { Injectable } from "@angular/core";
import { NavegacionService } from '../navegacion/navegacion.service';
import { AlertasService } from '../alertas/alertas.service';
import { TranslateService } from '@ngx-translate/core';
//import {NativeAudio} from '@capacitor-community/native-audio'
@Injectable({
	providedIn: "root"
})
export class OnesignalService {
	intervalUserAuth: any;
	intentosInterval: number = 0;
	apiService: any;
	constructor(
		private usuService: UsuarioService,
		private navegacionService: NavegacionService,
		private alertasService: AlertasService,
		private translate: TranslateService,
	) {

	}

	setApiService(api) {
		this.apiService = api;
	}

	init() {
		//if (this.platform.is("mobileweb")) return;
		(window as any).plugins?.OneSignal?.setAppId("89fea944-aa5e-4afa-86f3-8b0aa3ef4abe");

		(window as any).plugins?.OneSignal?.setNotificationWillShowInForegroundHandler((jsonData) => {
			(window as any).plugins?.OneSignal?.clearOneSignalNotifications();
			// do something when notification is received
			console.log('handleNotificationReceived: ', jsonData?.getNotification());
			let data:any = jsonData?.getNotification()?.additionalData;
			data.refresh = true;
			data.es_notificacion = true;
			/* NativeAudio.play({
				assetId: 'notification_sound',
				time: 0.0,
			}); */
			let buttons = [
				{
					text: this.translate.instant("generales.txt-cerrar"),
					handler: null
				},
				{
					text: this.translate.instant("generales.txt-ver"),
					handler: () => {
						this.goTo(data);
					}
				}
			]
			this.alertasService.presentAlertBtns(jsonData.getNotification().title, jsonData.getNotification().body, buttons);
		});

		(window as any).plugins?.OneSignal?.setNotificationOpenedHandler((jsonData) => {
			console.log('handleNotificationReceived: ', jsonData?.action);
			(window as any).plugins?.OneSignal?.clearOneSignalNotifications();
			let data:any = jsonData?.notification?.additionalData;
			if (data?.redirect) {
				let dataNotification: any = data;
				dataNotification.refresh = true;
				dataNotification.es_notificacion = true;
				this.intentosInterval = 1;
				this.alertasService.presentLoading();
				this.intervalUserAuth = setInterval(() => {
					this.intentosInterval++;
					if (this.intentosInterval >= 10) {
						this.alertasService.closePresentLoading();
						clearInterval(this.intervalUserAuth)
					};
					if (this.usuService?.usu?.id) {
						clearInterval(this.intervalUserAuth);
						this.alertasService.closePresentLoading();
						this.goTo(dataNotification);
						if (jsonData?.action?.actionId) {
							this.onActions(jsonData?.action?.actionId, dataNotification);
						}
					}
				}, 1000)
			}
		});

		(window as any).plugins?.OneSignal?.promptForPushNotificationsWithUserResponse(function(accepted) {
			console.log("User accepted notifications: " + accepted);
		});

		(window as any).plugins?.OneSignal?.getDeviceState((u) => {
			//if (this.platform.is("mobileweb")) return;
			this.usuService.onesignalPlayerId = u.userId;
		})

	}

	onActions(action, params) {
		switch (action) {
			case "no-estoy-instalando":
				let data: any = {};
				data.cliente_id = params.cliente_id;
				data.incidencia_id = params.incidencia_id;
				this.apiService.post("postinstaladornoinstalando", data)
					.subscribe(res => {
					}, err => {
						console.log(err);
					});
				break;

		}

	}

	removeExternalUserId() {
		//if (this.platform.is("mobileweb")) return;
		(window as any).plugins?.OneSignal?.removeExternalUserId();
	}

	setUserId(id) {
		//if (this.platform.is("mobileweb")) return;
		(window as any).plugins?.OneSignal?.setExternalUserId(String(id));
	}

	goTo(dataNotification) {
		this.navegacionService.goTo(dataNotification.redirect, dataNotification);
	}

}
