// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-msg-error {
  --background: transparent;
  color: var(--ion-color-danger-shade);
  display: flex;
  flex-direction: row;
  padding: 4px;
  margin: 0;
}
.form-msg-error ion-icon {
  color: var(--ion-color-danger-shade);
  font-size: 16px;
  margin: 0;
  margin-right: 4px;
}
.form-msg-error ion-label {
  font-size: 11px;
  margin: 0;
  margin-right: 4px;
  line-height: 17px;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/formularios/form-errors/form-errors.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,SAAA;AACJ;AAAI;EACI,oCAAA;EACA,eAAA;EACA,SAAA;EACA,iBAAA;AAER;AAAI;EACI,eAAA;EACA,SAAA;EACA,iBAAA;EACA,iBAAA;AAER","sourcesContent":[".form-msg-error {\n    --background: transparent;\n    color: var(--ion-color-danger-shade);\n    display: flex;\n    flex-direction: row;\n    padding: 4px;\n    margin: 0;\n    ion-icon{\n        color: var(--ion-color-danger-shade);\n        font-size: 16px;\n        margin: 0;\n        margin-right: 4px;\n    }\n    ion-label{\n        font-size: 11px;\n        margin: 0;\n        margin-right: 4px;\n        line-height: 17px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
