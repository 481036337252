import { Injectable } from '@angular/core';
import { FirebaseAnalytics, FirebaseInitOptions } from "@capacitor-community/firebase-analytics";
// Init for the web


// Initialize Firebase
const firebaseConfig: FirebaseInitOptions = {
  apiKey: "AIzaSyC3nvkKYxcHEOTphh3ABJxe2MmGLvct5IE",
  authDomain: "prosoty-ga4.firebaseapp.com",
  projectId: "prosoty-ga4",
  storageBucket: "prosoty-ga4.appspot.com",
  messagingSenderId: "360767182397",
  appId: "1:360767182397:web:c69237784bffa7de9c3c54",
  measurementId: "G-PMP3JBEBZ5"
};

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticsService {

  constructor(
  ) { }

  init() {
    FirebaseAnalytics.initializeFirebase(firebaseConfig);
  }

  setUserProperty(id, email) {
    FirebaseAnalytics.setUserId({
      userId: id,
    });

    FirebaseAnalytics.setUserProperty({
      "name": "id",
      "value": id,
    });
    FirebaseAnalytics.setUserProperty({
      "name": "email",
      "value": email,
    });
  }

  logEvent(name: string, params: any) {
    FirebaseAnalytics.logEvent({
      name: name,
      params: params
    }).then((res: any) => {
      //console.log(res)
    })
    .catch((error: any) => console.error(error));

  }

  setCurrentScreen(screenName) {
    FirebaseAnalytics.setScreenName({
      screenName: screenName,
      nameOverride: screenName+"Screen",
    })
    .then((res: any) => {
      //console.log(res)
    })
    .catch((error: any) => console.error(error));
  }

}
