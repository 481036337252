import { ModalController, Platform } from '@ionic/angular';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-modal-firma',
  templateUrl: './modal-firma.component.html',
  styleUrls: ['./modal-firma.component.scss'],
  standalone: false,
})
export class ModalFirmaComponent implements OnInit {

  signaturePad: SignaturePad;
  @ViewChild('canvas') canvasEl : ElementRef;
  signatureImg: string;
  status: boolean = false;

  constructor(
    public platform: Platform,
    public modalController: ModalController,
  ) { 
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  startDrawing(event: Event) {
    // works in device not in browser
    this.onCanvas()
  }

  moved(event: Event) {
    // works in device not in browser
    this.onCanvas()
  }

  onCancel() {
    this.modalController.dismiss();
  }


  onClearPad() {
    this.signaturePad.clear();
  }

  onCanvas() {
    this.status = true;
  }

  onSavePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.modalController.dismiss(base64Data);

  }

}